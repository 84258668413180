import { setGlobalErrorMsg, getErrorText } from '../store/global/globalActions';
import { orderBy } from 'lodash';
import DialogHelper from './dialog';

/**
 * Parses the error and returns a text message.
 *
 * @param   mixed  error
 * @return  {String}
 */
export const getErrorMsg = error => {
  return getErrorText(error);
};

/**
 * Sort data by the specified field.
 *
 * @param {Array|Object}  data        The collection to iterate over.
 * @param {String[]}      iteratees   The iteratees to sort by.
 * @param {boolean}       asc         The sorting order (ascending).
 *
 * @return  {Array}
 */
export const sortByField = (data, iteratees, asc) => {
  asc = asc === undefined ? true : false; // default
  try {
    return orderBy(data, [iteratees], [asc ? 'asc' : 'desc']);
  } catch (error) {
    return data;
  }
};

/**
 * Sort two rows
 */
export const sortByFileSize = (rowA, rowB, field) => {
  return rowA[field] - rowB[field];
};

/**
 * Sort data alphabetically (A-Z)
 *
 * @param {Array|Object}  data        The collection to iterate over.
 *
 * @return  {Array}
 */
export const sortDataAlph = data => {
  const mapper = e => e.label.toLowerCase();
  return sortByField(data, mapper);
};

/**
 * Sort data by the specified field.
 *
 * @param {Array|Object}  data        The collection to iterate over.
 * @param {String}        iteratee    The iteratee to sort by.
 * @param {boolean}       asc         The sorting order (ascending).
 *
 * @return  {Array}
 */
export const sortByNumericField = (data, iteratee, asc) => {
  const mapper = e => parseInt(e[iteratee]);
  return sortByField(data, mapper, asc);
};

/**
 * Handles filter URL query parameters.
 *
 * @param   {Array}  value
 * @return  {void}
 */
export const handleFilterURLParamsChanges = (filters, history, custom) => {
  const allFilters = [].concat.apply([], Object.values(filters));
  const activeFilters = allFilters.filter(f => f.is_checked);
  const params = new URLSearchParams();
  if (!isEmpty(activeFilters)) {
    activeFilters.forEach(f => {
      params.append('filter', f.value);
    });
  }
  let currentParams = params.toString();
  //custom means the page url contain other params, so we need to concate it with the 
  // new/added params ,,, in most cases will be the filters
  if (custom) {
    let _pathForFilters = !isEmpty(currentParams) ? `&${currentParams}` : '';
    currentParams = history.location.search.split('&filter')[0] + _pathForFilters;
  }
  if (!isEmpty(activeFilters)) {
    history.push({
      pathname: '',
      search: currentParams,
    });
  } else {
    history.replace({
      pathname: '',
      search: currentParams,
    });
  }
};

/**
 * Checks if the value is undefined.
 * 
 * @param   {any type}  value
 * @return  {boolean}
 */
export const isUndefined = value => typeof value === 'undefined';

/**
 * Checks if the value is null.
 * 
 * @param   {any type}  value
 * @return  {boolean}
 */
export const isNull = value => value === null;

/**
 * Checks if the value is undefined or null.
 * 
 * @param   {any type}  value
 * @return  {boolean}
 */
export const isNullOrUndefined = value => isUndefined(value) || isNull(value);

/**
 * Checks if value is Numeric.
 *
 * @param   {any type} 	value
 * @return  {boolean}
 */
export const isNotEmpty = value => value !== null && value !== undefined;

/**
 * Checks if value is Numeric.
 *
 * @param   {any type} 	value
 * @return  {boolean}
 */
export const hasFilesizeUnit = value => isNotEmpty(value) && (value.includes('TB') || value.includes('GB') || value.includes('MB') || value.includes('KB') || value.includes('B'));

/**
 * Checks if value is not null and not undefined.
 *
 * @param   {any type} 	value
 * @return  {boolean}
 */
export const isValidSize = value => isNotEmpty(value) && !isNaN(value);

/**
 * Checks if value is an empty object or array.
 *
 * @param   {Object|Array} 	value
 * @return  {boolean}
 */
export const isEmpty = value => [Object, Array].includes((value || {}).constructor) && !Object.entries(value || {}).length;

/**
 * Checks if value is an empty object or array or null.
 *
 * @param   {Object|Array}  value
 * @return  {boolean}
 */
export const isEmptyOrNull = value => {
  return value === null || isEmpty(value);
};

/**
 * Checks if the value is of type object.
 *
 * @param   {Object}  value
 * @return  {boolean}
 */
export const isObject = value => {
  return typeof value === 'object' || value instanceof Object;
};

/**
 * Checks if the value is of type array.
 *
 * @param   {any type}  value
 * @return  {boolean}
 */
export const isArray = value => {
  return Array.isArray(value);
};

/**
 * Checks if the value is of type array/object.
 *
 * @param   {any type}  value
 * @return  {boolean}
 */
export const isComplex = value => {
  return isArray(value) || isObject(value);
};

/**
 * Checks if the value is of type function.
 *
 * @param   {Object}  value
 * @return  {boolean}
 */
export function isFunction(value) {
 return value && {}.toString.call(value) === '[object Function]';
}

/**
 * Checks if the object has the specified key.
 *
 * @param   {Object}  value
 * @param   {String}  key
 * @return  {boolean}
 */
export function hasProperty(value, key) {
 return isObject(value) && (key in value);
}

/**
 * Checks if the value is of type string.
 *
 * @param   {Object}  value
 * @return  {boolean}
 */
export const isString = value => {
  return typeof value === 'string' || value instanceof String;
};

/**
 * Checks if the value is of type boolean.
 *
 * @param   {Object}  value
 * @return  {boolean}
 */
export const isBoolean = value => {
  return typeof value === 'boolean' || value instanceof Boolean;
};

/**
 * Handles the error response from the server.
 * 
 * @param   {Function}  dispatch
 * @param   {Object}    error
 * @return  {Object}
 * @private
 */
const _handleRequestError = (dispatch, error) => {
  dispatch(setGlobalErrorMsg(error));
}

/**
 * Display the error message in a modal dialog.
 * 
 * @param   {Object}  modalDialog
 * @param   {Object}  error
 * @return  {void}
 */
export const displayResponseError = (modalDialog, error, dispatch) => {
  dispatch && dispatch(setGlobalErrorMsg(error));
  DialogHelper.error(modalDialog, getErrorMsg(error));
}

/**
 * A wrapper for the get action dispatcher.
 *
 * @param   {Promise} promise
 * @param   {string}  type
 * @param   {Object}  params
 * @return  {Function}
 */
export const dispatchGetPromise = (promise, type, params) => dispatch => {
  return new Promise((resolve, reject) => {
    promise
      .then(res => {
        if (Array.isArray(type)) {
          type.forEach(t => {
            dispatch({
              type: t,
              params,
              payload: res,
            });
          });
        } else if (type) {
          dispatch({
            type,
            params,
            payload: res,
          });
        }
        resolve(res);
      })
      .catch(error => {
        _handleRequestError(dispatch, error);
        reject(error);
      });
  });
};

/**
 * A wrapper for the post action dispatcher.
 *
 * @param   {Promise} promise
 * @param   {string}  type
 * @return  {Function}
 */
export const dispatchPostPromise = dispatchGetPromise;

/**
 * A wrapper for the delete action dispatcher.
 *
 * @param   {Promise} promise
 * @param   {string}  type
 * @param   {Object}  actionPayload
 * @return  {Function}
 */
export const dispatchDeletePromise = (promise, type, actionPayload) => dispatch => {
  return new Promise((resolve, reject) => {
    promise
      .then(res => {
        if (type) {
          dispatch({
            type,
            payload: actionPayload ? actionPayload : res
          });
        }
        resolve(res);
      })
      .catch(error => {
        _handleRequestError(dispatch, error);
        reject(error);
      });
  });
};

/**
 * Return an array without duplicates. Similar to Lodash _.unuqBy
 *
 * @param   {Array}           arr
 * @param   {string|Function} predicate
 * @return  {Array}
 */
export const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : o => o[predicate];
  return [
    ...arr
    .reduce((map, item) => {
      const key = item === null || item === undefined ? item : cb(item);
      map.has(key) || map.set(key, item);
      return map;
    }, new Map())
    .values(),
  ];
};

/**
 * Export data to CSV file.
 * 
 * The data must be an array of objects with the same keys as the headers.
 *
 * For example:
 * [{ name: 'John', age: 30}, { name: 'Jane', age: 25}]
 * 
 * @param {array} data 
 * @param {string} filename 
 */
export const exportCsvToFile = (data, filename) => {
  if (!data || !data.length) {
    window.logHelper.warning('No data to export.');
    return;
  }
  const headers = Object.keys(data[0]);
  const rows = data.map(it => Object.values(it));
  const csvData = [headers, ...rows].join('\n');
  filename = (filename || 'export').replace('.csv', '');

  let hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
  hiddenElement.target = '_blank';
  hiddenElement.download = `${filename}.csv`;
  hiddenElement.click();
  hiddenElement.remove();
}

export const getStateColor = state => {
  let color;
  ['modifying', 'optimizing', 'deleting', 'deleted', 'insufficient-data', 'pending'].includes(state)
    ? (color = 'warning')
    : ['completed', 'available', 'in-use', 'ok', 'active'].includes(state)
    ? (color = 'success')
    : ['error', 'failed', 'impaired'].includes(state)
    ? (color = 'danger')
    : (color = 'info');
  return color;
};

export const getStatusColor = data => {
  const status = isString(data) ? data : data.status;
  let color = 'primary'; // default
  if (['success', 'ok', 'valid', 'completed', 'active', 'sent'].includes(status)) color = 'success';
  if (['impaired', 'not-applicable', 'error', 'expired', 'invalid', 'failed'].includes(status)) color = 'danger';
  if (['ok-has-warning', 'paused', 'inactive'].includes(status)) color = 'warning';
  if (status === 'initializing') color = 'info';
  return color;
};

export const openNewTab = link => window.open(link, '_blank');

export const isServerBusy = resource => {
  if (!resource) {
    window.logHelper.warning('Received empty resource as server.');
    return false;
  }
  return resource && !['running', 'not-applicable', 'failed'].includes(resource.state)
};

export const isWebsiteBusy = resource => {
  return resource && ![
    'available',
    'not-applicable',
    'failed',
    'migration:offloading-s3',
    'migration:finalizing-config',
    'sync:finalizing-config',
    'sync:pending',
  ].includes(resource.state);
};

export const convertToFilterObject = (query, name, set) => {
  let currentFilters = Array.from(query.values('filter'));
  let filter = {
    [name]: []
  };
  set.forEach(v => {
    const _value = `${name}:${v}`;
    const _checked = currentFilters.includes(_value);
    filter[name].push({
      name: v,
      value: _value,
      is_checked: _checked
    });
  });

  // Sort boolean values from positive to negative
  if (filter[name] && filter[name].length === 2) {
    filter[name] = filter[name].sort(function (a, b) {
      if ((a.name === 'Yes' && b.name === 'No') || (a.name === 'true' && b.name === 'false')) {
        return -1;
      } else if (
        (b.name === 'Yes' && a.name === 'No') ||
        (b.name === 'true' && a.name === 'false')
      ) {
        return 1;
      }
      return a.name < b.name;
    });
  }
  return filter;
};

/**
 * Calls a function after a delay.
 *
 * @param   {int}  time
 * @return  {Promise}
 */
export const delayPromise = time => {
  return new Promise(resolve => setTimeout(resolve, time + Math.floor(Math.random() * 1000)));
}

/**
 * Compares two software version numbers (e.g. '1.7.1' or '1.2b').
 *
 * This function was born in http://stackoverflow.com/a/6832721.
 *
 * @param {string} v1 The first version to be compared.
 * @param {string} v2 The second version to be compared.
 * @param {object} [options] Optional flags that affect comparison behavior:
 * <ul>
 *     <li>
 *         <tt>lexicographical: true</tt> compares each part of the version strings lexicographically instead of
 *         naturally this allows suffixes such as 'b' or 'dev' but will cause '1.10' to be considered smaller than
 *         '1.2'.
 *     </li>
 *     <li>
 *         <tt>zeroExtend: true</tt> changes the result if one version string has less parts than the other. In
 *         this case the shorter string will be padded with 'zero' parts instead of being considered smaller.
 *     </li>
 * </ul>
 * @returns {number|NaN}
 * <ul>
 *    <li>0 if the versions are equal</li>
 *    <li>a negative integer if v1 < v2</li>
 *    <li>a positive integer if v1 > v2</li>
 *    <li>NaN if either version string is in the wrong format</li>
 * </ul>
 *
 * @copyright by Jon Papaioannou (['john', 'papaioannou'].join('.') + '@gmail.com')
 * @license This function is in the public domain. Do what you want with it, no strings attached.
 */
export function versionCompare(v1, v2, options) {
  if (v1 === v2) {
    return 0;
  } else if (!v1) {
    return -1;
  } else if (!v2) {
    return 1;
  } else if (!isString(v1) && isString(v2)) {
    return -1;
  } else if (isString(v1) && !isString(v2)) {
    return 1;
  } else if (!isString(v1) && !isString(v2)) {
    return NaN;
  }
  var lexicographical = options && options.lexicographical;
  var zeroExtend = options && options.zeroExtend;
  var v1parts = v1.split('.');
  var v2parts = v2.split('.');

  function isValidPart(x) {
    return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
  }

  if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
    return NaN;
  }

  if (zeroExtend) {
    while (v1parts.length < v2parts.length) v1parts.push('0');
    while (v2parts.length < v1parts.length) v2parts.push('0');
  }

  if (!lexicographical) {
    v1parts = v1parts.map(Number);
    v2parts = v2parts.map(Number);
  }

  for (var i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1;
    }

    if (v1parts[i] === v2parts[i]) {
      continue;
    } else if (v1parts[i] > v2parts[i]) {
      return 1;
    } else {
      return -1;
    }
  }

  if (v1parts.length !== v2parts.length) {
    return -1;
  }

  return 0;
}

/**
 * Saves a key/value pair to session storage (persists as long as the tab is open).
 *
 * @param {string} key
 * @param {string} value
 * @returns {void}
 */
export function saveToSessionStorage(key, value) {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(key, value);
  }
}

/**
 * Retrieves a value from session storage.
 *
 * @param {string} key
 * @returns {string|null}
 */
export function getFromSessionStorage(key) {
  if (window.sessionStorage) {
    return window.sessionStorage.getItem(key);
  }
  return null;
}
