import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { websitesSelector } from 'store/website/websiteSelectors';
import { WPSForm } from 'styles/layout/forms';
import { Container } from 'styles/website/profile';
import { TitleBar } from 'styles/layout/titlebar';
import { useForm } from 'react-hook-form';
import { Row } from 'styles/layout/grid';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import { useHistory } from 'react-router-dom';
import {
  deactivateWebsitePlugin,
  activateWebsitePlugin,
  updateActiveTheme,
  uninstallPackage,
} from 'store/website/websiteActions';
import { setGlobalSuccessMsg, setGlobalPleaseWaitMsg } from 'store/global/globalActions';
import ReportService from 'services/report';
import JsxHelper from 'helpers/jsx';
import PackageHelper from 'helpers/package';
import DialogHelper from 'helpers/dialog';
import UserHelper from 'helpers/user';
import ArrayHelper from 'helpers/array';
import TableHelper from 'helpers/table';
import useConfirm from 'hooks/useConfirm';
import { partnersSelector } from 'store/user/userSelectors';
import 'components/package/packages.css';
import { isEmptyOrNull, versionCompare } from 'helpers';

const SearchWebsitePackages = () => {
  const { handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const history = useHistory();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const allWebsites = useSelector(websitesSelector);
  const [tableData, setTableData] = useState(null);
  const [showTable, setShowTable] = useState(false);
  const [searchType, setSearchType] = useState('plugin');
  const [searchVal, setSearchVal] = useState('');
  const [selectedPartner, setSelectedPartner] = useState('');
  const [strictSearch, setStrictSearch] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [loadingActive, setLoadingActive] = useState(null);
  const allPartners = useSelector(partnersSelector);

  // ------------------------------------------------------------------
  // API Methods
  // ------------------------------------------------------------------

  const handleSearch = () => {
    setSearchLoading(true);
    const data = {
      search: searchVal,
      package_type: searchType,
      strict: strictSearch,
    }
    if (UserHelper.isAdminOrAgent() && !isEmptyOrNull(selectedPartner)) {
      data.partner_slug = selectedPartner;
    }
    ReportService.filterAppsInstalledPacakges(data).then(res => {
      setTableData(res.map(item => {
        item.slug = item.website_slug + ':' + item.name;
        item.is_active = item.status === 'active';
        item.latest_version = item.update;
        item.latest_version_url = item.update_url;
        delete item.status;
        return item;
      }));
      setShowTable(true);
    }).finally(() => {
      setSearchLoading(false);
    });
  };

  const changePackageState = (item, el, action, callback) => {
    const actionFn = item.type === 'plugin'
      ? (action === 'activate' ? activateWebsitePlugin : deactivateWebsitePlugin)
      : updateActiveTheme;

    setLoadingActive(item.slug);
    dispatch(setGlobalPleaseWaitMsg({ model: item.type, id: item.display_name, action: action + 'd' }));

    const data = {
      website_slug: item.website_slug,
      folder_name: item.name,
    };
    dispatch(actionFn(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ model: item.type, id: item.display_name, action: action + 'd' }));
        setTableData(prev => prev.map(p => {
          if (p.slug === item.slug) {
            p.is_active = action === 'activate';
          }
          return p;
        }));
        callback && callback();
      }).finally(() => {
        setLoadingActive(false);
        if (el && el.classList) {
          el.classList.remove('loadingspinner');
        }
      });
  };

  // ------------------------------------------------------------------
  // Event Handlers
  // ------------------------------------------------------------------

  const handlePackageDeletion = (item) => {
    dispatch(setGlobalPleaseWaitMsg({ model: item.type, action: 'deleted' }));
    if (PackageHelper.isUnmanaged(item)) {
      const data = {
        website_slug: item.website_slug,
        type: item.type,
        folder_name: item.name,
      };
      dispatch(uninstallPackage(data)).then(() => {
        dispatch(setGlobalSuccessMsg({ id: item.display_name, model: item.type, action: 'deleted' }));
        setTableData(prev => prev.filter(p => p.slug !== item.slug));
      });
    } else if (PackageHelper.isGlobalOrPartner(item)) { // Can only be deactivated, not deleted.
      changePackageState(item, null, 'deactivate', () => {
        setTableData(prev => prev.filter(p => p.slug !== item.slug));
      });
    }
  }

  const handlePackageActivation = (item, checked, el) => {
    if (loadingActive) {
      dispatch(setGlobalPleaseWaitMsg({ model: item.type, action: checked ? 'activated' : 'deactivated' }));
      return;
    }
    return checked ? changePackageState(item, el, 'activate') : changePackageState(item, el, 'deactivate');
  };

  const actions = [
    {
      value: 'Delete',
      doHide: (item) => PackageHelper.isGit(item) ||
                        !UserHelper.hasPermissions(`website:update:${item.website_slug}`),
      onClick: item => DialogHelper
        .confirmDelete(confirm, item && item.display_name ? item.display_name : '', item && item.type ? item.type : 'package')
        .then(() => handlePackageDeletion(item))
    },
  ];

  const headers = [
    PackageHelper.renderScopeHeader(),
    PackageHelper.renderDisplayNameHeader('Name'),
    {
      name: 'Website',
      selector: 'website_slug',
      sortable: true,
      searchable: true,
      width: '27%',
      cell: (row) => {
        const website = allWebsites.find(w => w.slug === row.website_slug);
        return JsxHelper.createTableWebsiteCell({
          website,
          paddingRight: '10px',
        })
      }
    },
    {
      name: 'Version',
      selector: 'version2',
      searchable: true,
      sortable: true,
      sortFunction: (a, b) => versionCompare(a.version, b.version),
      width: '10%',
      cell: row => {
        TableHelper.customizeCellValue(row, 'version2', row.version);
        return PackageHelper.renderVersionCell({
          ...row,
          style: { margin: 'inherit'},
        });
      }
    },
    {
      name: 'Active',
      selector: 'is_active2',
      sortable: true,
      width: '10%',
      cell: row => PackageHelper.renderActivateCell(
        { ...row, style: { margin: 'inherit'}},
        handlePackageActivation,
        loadingActive
      ),
    },
    JsxHelper.createTableActionsHeader(actions, '22%'),
  ];

  const breadcrumbs = JsxHelper.createBreadcrumbs(`Search Plugins / Themes`, 'tools');

  return (
    <Container className='margin-0'>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}> Search Plugins / Themes</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createButton({
            label: 'Back',
            onClick: history.goBack,
          })}
        </TitleBar.Actions>
      </TitleBar>
      <WPSForm
        onSubmit={e => e.preventDefault()}
        className='margin-24'
        style={{ marginTop: '50px' }}>
        <p className='color-primary'>Searches through all your websites for installed plugins or themes that match the search criteria.</p>
        {UserHelper.isAdminOrAgent() && (JsxHelper.createSelectInput({
          label: 'Partner',
          name: 'partner_slug',
          value: selectedPartner,
          options: ArrayHelper.buildSelectOptions(allPartners, 'display_name', 'slug'),
          sortOff: true,
          isSearchable: true,
          noneOption: true,
          noneText: 'Any',
          onChange: e => setSelectedPartner(e.target.value),
          placeholder: 'Select partner...',
        }))}
        {JsxHelper.createSelectInput({
          label: 'Type',
          name: 'type',
          tooltip: 'Select the type of package to search for.',
          className: 'width-30',
          value: searchType,
          onChange: e => setSearchType(e.target.value),
          options: [
            { label: 'Plugin', value: 'plugin' },
            { label: 'Theme', value: 'theme' },
          ]
        })}
        {JsxHelper.createTextInput({
          label: 'Search Term',
          name: 'search',
          tooltip: 'Enter a search term to search for installed plugins or themes across all websites based on the directory name or the display name as shown in the WordPress admin.',
          className: 'width-30',
          value: searchVal,
          onChange: e => setSearchVal(e.target.value),
          placeholder: 'Enter search term...',
        })}
        {JsxHelper.createCheckbox({
          label: 'Exact Match',
          name: 'strict',
          tooltip: 'Enable this option to search for an exact match of the search term.',
          className: 'width-30',
          checked: strictSearch,
          onChange: e => setStrictSearch(e.target.checked),
        })}
        <Row className='action-buttons'>
          {JsxHelper.createButton({
            label: 'Search',
            loading: searchLoading,
            disabled: searchLoading || searchVal.length <= 3,
            onClick: handleSubmit(handleSearch),
          })}
        </Row>
      </WPSForm>
      <div className='margin-24'>
        {showTable && <WPSDataTable customClass='packages-table' columns={headers} body={tableData || []} />}
      </div>
    </Container>
  );
};
export default SearchWebsitePackages;
