import { postRequest, getRequest, deleteRequest } from '../config/axiosRequest';
import env from '../config/env';

// Fetch all websites
const fetchAll = data => {
  return getRequest({
    url: env.buildApiEndpoint('websites'),
    data,
  });
};

/**
 * Returns a website by the specified slug.
 *
 * @param   {string}  slug
 * @return  {Array}
 */
const fetchSingle = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}`),
    data
  });
};

/**
 * Creates a new website.
 *
 * @param   {Array} data
 * @return  {Array}
 */
const create = data => {
  return postRequest({
    url: env.buildApiEndpoint('websites'),
    data,
  });
};

/**
 * Clones a new website.
 *
 * @param   {Array} data
 * @return  {Array}
 */
 const clone = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.src_website_slug}/clone`),
    data,
  });
};

/**
 * Creates a new staging website.
 *
 * @param   {Array} data
 * @return  {Array}
 */
 const createStaging = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/staging`),
    data,
  });
};

/**
 * Updates website label.
 *
 * @param   {Array} data
 * @return  {Array}
 */
const updateLabel = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/label`),
    data,
  });
};

/**
 * Creates a filemanager access token.
 *
 * @param   {Array}   data
 * @return  {Array}
 */
const createFileManagerToken = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/filemanager-token`),
    data
  });
};

/**
 * Update the website's domains.
 *
 * @param   {string}  slug
 * @param   {Array}   data
 * @return  {Array}
 */
const updateDomains = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/domains`),
    data,
  });
};

// Check if website exists
const checkIfExists = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/exists`),
    data,
  });
};

// Retrieve a website's packages data
const listInstalledPackages = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/packages`),
    data,
  });
};

// Create a website login token
const createWPLoginToken = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/login-token`),
    data,
  });
};

// Activate a website plugin
const activatePlugin = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/plugins/${data.folder_name}/activate`),
    data,
  });
};

// Deactivate a website plugin
const deactivatePlugin = data => {
  return postRequest({
    url: env.buildApiEndpoint(
      `websites/${data.website_slug}/plugins/${data.folder_name}/deactivate`,
    ),
    data,
  });
};

// Update website active theme
const activateTheme = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/themes/${data.folder_name}`),
    data,
  });
};

// Delete speicifed website.
const del = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}`),
    data,
  });
};

// Restore a website's backup
const restoreBackup = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/restore-backups`),
    data,
  });
};

// Retrieve a website's backup download URL
const downloadBackup = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/download-backup`),
    data,
  });
};

// Deletes website backups
const deleteBackups = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/backups`),
    data,
  });
};

// Fetch website backups
const fetchWebsiteBackups = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/backups`),
    data,
  });
}

// Fetch website backup requests
const fetchSyncRequests = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/sync-requests`),
    data,
  });
}

// Fetch website staging sync requests
const fetchStagingSyncRequests = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/sync-staging-requests`),
    data,
  });
}

// Sync staging to live
const syncStagingToLive = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/sync-staging`),
    data,
  });
}

// Toggle a website's debug mode
const debug = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/wp-debug`),
    data,
  });
};

// Install a website's package
const installPackage = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/install-package`),
    data,
  });
};

// Uninstall a website's package
const uninstallPackage = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/uninstall-package`),
    data,
  });
};

// Fetch nginx/wp/php-fpm logs
const fetchAppServerLogs = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/server-logs`),
    data,
  });
};

// Fetch cloudfront/s3 logs
const fetchAppAwsLogs = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/aws-logs`),
    data,
  });
}

// Get SFTP user
const getSftpUser = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/sftp`),
    data,
  });
}

// Update SFTP user
const updateSftpUser = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/sftp`),
    data,
  });
}

// Fetch website default credentials
const fetchCredentials = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/credentials`),
    data,
  });
};

// Rest website credentials
const resetCredentials = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/credentials`),
    data,
  });
};

// Create a manual backup
const createBackup = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/backups`),
    data,
  });
};

// Assign DNS zone
const assignDNSZone = data => {
  return postRequest({
    url: env.buildApiEndpoint(
      `websites/${data.website_slug}/dnszones/${data.dns_zone_slug}/assign`,
    ),
    data,
  });
};

// Unassign DNS zone
const unassignDNSZone = data => {
  return postRequest({
    url: env.buildApiEndpoint(
      `websites/${data.website_slug}/dnszones/${data.dns_zone_slug}/unassign`,
    ),
    data,
  });
};

// Fetch website activity history
const fetchWebsiteActivites = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/activity`),
    data,
  });
};

// Purge a website page cache
const purgeCache = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/wp-cache/purge`),
    data,
  });
};

// Replace a website's cache HTML
const replaceCacheHTML = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/wp-cache/replace-html`),
    data,
  });
};

// Update a website's redirect rules
const updateRedirectRules = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/redirects`),
    data,
  });
};

// Fetch website SSL certificate
const fetchSsl = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/ssl`),
    data,
  });
};

// Requests website SSL certificate
const requestSsl = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/ssl`),
    data,
  });
};

// Issue a website Acm certificate
const issueAcmCertificate = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/acm-certificates`),
    data,
  });
};

// Sync a website Acm certificate
const syncAcmCertificate = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/acm-certificates/sync`),
    data,
  });
};

// Revoke a website Acm certificate
const revokeAcmCertificate = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/acm-certificates`),
    data,
  });
};

// Deploy website CDN proxy
const deployCdnProxy = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/cdns/proxy`),
    data,
  });
};

// Deletes website CDN proxy
const deleteCdnProxy = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/cdns/proxy`),
    data,
  });
};

// Deploy a website CDN cache
const deployCdnCache = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/cdns/cache`),
    data,
  });
};

// Delete a website CDN cache
const deleteCdnCache = data => {
  return deleteRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/cdns/cache`),
    data,
  });
};

// Sync a website CDN
const syncCdn = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/cdns/sync`),
    data,
  });
};

const invalidateCDNPaths = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/cdns/invalidate-paths`),
    data,
  });
}

// Update wordpress version (returns website object)
const updateWPVersion = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/wp-version`),
    data,
  });
};

// updateWPCoreAutoUpdate
const updateWPCoreAutoUpdate = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/wp-core-auto-update`),
    data,
  });
}

// Perform a website's database search/replace (returns rows affected)
const sendSearchReplaceDBRequest = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/search-replace-db-request`),
    data,
  });
};

// Stop a website's database search/replace process
const stopSearchReplaceDBProcess = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/search-replace-db-stop`),
    data,
  });
};

// Get a website's database search/replace status
const getSearchReplaceDBStatus = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/search-replace-db-status`),
    data,
  });
};

// Run a website's WP CLI command (returns website object)
const WPCLICommand = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/wp-cli`),
    data,
  });
};

// Tests teh website's PHP version (returns status object)
const testPHPVersion = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/test-php-version`),
    data,
  });
};

// Update a website's PHP config (returns website object)
const updatePHPConfig = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/php-config`),
    data,
  });
};

// Update a website's Nginx config (returns website object)
const updateNginxConfig = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/nginx-config`),
    data,
  });
};

// Update a website's hide login config (returns website object)
const updateHideLoginConfig = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/hide-login-config`),
    data,
  });
};

const updateObjectCacheConfig = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/object-cache-config`),
    data,
  });
};

const flushRedisCache = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/flush-redis-cache`),
    data,
  });
};

// Get SMTP details
const fetchSMTPConfig = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/smtp`),
    data,
  });
};

// Update a website's SMTP config
const updateWebsiteSMTPConfig = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/smtp/config`),
    data,
  });
};

// Update Global SMTP config
const updateGlobalSMTPConfig = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/smtp-config`),
    data,
  });
};

// Update Global Nginx config
const updateGlobalNginxConfig = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/nginx-config`),
    data,
  });
};

// Update custom deployment config
const updateGlobalCustomDeploymentConfig = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/custom-deployment-config`),
    data,
  });
};

// Update Auto Updater config
const updateGlobalAutoUpdaterConfig = data => {
  return postRequest({
    url: env.buildApiEndpoint(`packages/auto-updater-config`),
    data,
  });
};

// Installs SMTP Plugin on website
const installSMTPPluginOnWebsite = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/smtp/install`),
    data,
  });
};

// Set a website's PHP constants
const updateWPDefines = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/wp-defines`),
    data,
  });
};

// Get a website's PHP constants
const fetchWPDefines = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/wp-defines`),
    data,
  });
};

// Update a website's cron jobs
const updateCronJobs = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/crons`),
    data,
  });
};

// List a website's analytics
const fetchTrafficAnalytics = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/analytics/traffic`),
    data,
  });
};

// List a website's analytics
const fetchPHPWorkersAnalytics = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/analytics/php-workers`),
    data,
  });
};

// Set global PHP constants
const updateGlobalPHPConstants = data => {
  return postRequest({
    url: env.buildApiEndpoint('websites/wp-defines'),
    data,
  });
};

// Bulk reset default credentials
const bulkReset = data => {
  return postRequest({
    url: env.buildApiEndpoint('websites/bulk-reset-credentials'),
    data,
  });
};

// Bulk purge WP cache
const BulkPurge = data => {
  return postRequest({
    url: env.buildApiEndpoint('websites/bulk-purge-wp-cache'),
    data,
  });
};

// Perform a website's database search
const databaseSearch = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/search-db`),
    data,
  });
};

// Run a website's database Query
const databaseRunQuery = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/query-db`),
    data,
  });
};

// Lists banned IPs.
const listBannedIPs = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/banned-ips`),
    data,
  });
};

// Reports IP as non-spam.
const reportNotSpam = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/report-not-spam`),
    data,
  });
};

// Removes banned IP.
const removeBannedIP = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/remove-banned-ip`),
    data,
  });
};

// Sets the auto update flag for a website package (managed/unmanaged)
const setPackageAutoUpdate = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/auto-update-package`),
    data,
  });
}

// Get website subscriptions 
const getSubscriptions = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/subscriptions`),
    data,
  });
}

// Get website database info 
const getDatabaseInfo = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/database`),
    data,
  });
}

// Unassign customer from website
const unassignCustomer = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/unassign-customer`),
    data,
  });
}

const testDomains = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/test-domains`),
    data,
  });
}

/**
 * Generates unique SSH + Webhook API keys.
 *
 * @return {Object}
 */
const generateGitKeys = () => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/git-keys`),
    data: {}
  });
};

/**
 * Checks if a git repository is clonable.
 *
 * @param   {Object}   data
 * @return {Object}
 */
const isGitClonable = (data) => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/git-test-clone`),
    data
  });
};

/**
 * Creates a new git website.
 * 
 * @param   {Array} data
 * @return  {Array}
 */
const createGit = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/git-deploy`),
    data,
  });
}

/**
 * Pulls git changes from the git repository to the website.
 * 
 * @param   {Array} data
 * @return  {Array}
 */
const pullGit = data => {
  return postRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/git-pull`),
    data,
  });
}

/**
 * Lists git events.
 * 
 * @param   {Array} data
 * @return  {Array}
 */
const fetchGitEvents = data => {
  return getRequest({
    url: env.buildApiEndpoint(`websites/${data.website_slug}/git-events`),
    data,
  });
}

const WebsiteService = {
  fetchAll,
  fetchSingle,
  create,
  createGit,
  clone,
  updateDomains,
  activatePlugin,
  deactivatePlugin,
  activateTheme,
  checkIfExists,
  delete: del,
  restoreBackup,
  fetchWebsiteBackups,
  debug,
  fetchAppServerLogs,
  fetchAppAwsLogs,
  fetchCredentials,
  resetCredentials,
  createBackup,
  assignDNSZone,
  unassignDNSZone,
  fetchWebsiteActivites,
  purgeCache,
  replaceCacheHTML,
  updateRedirectRules,
  issueAcmCertificate,
  syncAcmCertificate,
  revokeAcmCertificate,
  invalidateCDNPaths,
  updateWPVersion,
  sendSearchReplaceDBRequest,
  getSearchReplaceDBStatus,
  stopSearchReplaceDBProcess,
  WPCLICommand,
  updatePHPConfig,
  updateWPDefines,
  fetchWPDefines,
  updateCronJobs,
  fetchTrafficAnalytics,
  fetchPHPWorkersAnalytics,
  updateGlobalPHPConstants,
  bulkReset,
  BulkPurge,
  databaseSearch,
  databaseRunQuery,
  fetchSMTPConfig,
  updateWebsiteSMTPConfig,
  updateGlobalSMTPConfig,
  installSMTPPluginOnWebsite,
  updateGlobalNginxConfig,
  updateGlobalAutoUpdaterConfig,
  createFileManagerToken,
  fetchSsl,
  requestSsl,
  getSftpUser,
  updateSftpUser,
  updateNginxConfig,
  updateHideLoginConfig,
  deployCdnCache,
  deleteCdnCache,
  deployCdnProxy,
  deleteCdnProxy,
  syncCdn,
  listBannedIPs,
  reportNotSpam,
  removeBannedIP,
  downloadBackup,
  updateObjectCacheConfig,
  testPHPVersion,
  deleteBackups,
  listInstalledPackages,
  installPackage,
  uninstallPackage,
  setPackageAutoUpdate,
  getSubscriptions,
  getDatabaseInfo,
  updateLabel,
  unassignCustomer,
  flushRedisCache,
  fetchSyncRequests,
  createStaging,
  fetchStagingSyncRequests,
  syncStagingToLive,
  updateGlobalCustomDeploymentConfig,
  createWPLoginToken,
  updateWPCoreAutoUpdate,
  testDomains,
  generateGitKeys,
  isGitClonable,
  fetchGitEvents,
  pullGit,
};

export default WebsiteService;
